import {useCallback, useEffect, useRef} from "react";

type UseTabReopenEffectReturn<AutoListen extends boolean> = AutoListen extends true ? undefined : () => void;

function useTabReopenEffect<AutoListen extends boolean>(
    onTabReopen: () => void,
    autoListen: AutoListen,
    delay = 1000 * 60 * 15
): UseTabReopenEffectReturn<AutoListen> {
    const shouldRunReopenEffect = useRef(false);
    const awaitingTabLeave = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const startListeningForLeave = () => awaitingTabLeave.current = true;

    const scheduleReopenCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            shouldRunReopenEffect.current = true;
        }, delay);
    }, [delay]);

    const cancelScheduledReopenCallback = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    useEffect(() => {
        const handleTabLeave = () => {
            if (awaitingTabLeave.current || autoListen) {
                awaitingTabLeave.current = false;
                scheduleReopenCallback();
            }
        }
        const handleTabEnter = () => {
            cancelScheduledReopenCallback();
            if (shouldRunReopenEffect.current) {
                shouldRunReopenEffect.current = false;
                onTabReopen();
            }
        }
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                handleTabEnter();
            } else {
                handleTabLeave();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            cancelScheduledReopenCallback();
        };
    }, [onTabReopen, autoListen, delay, scheduleReopenCallback]);

    return (autoListen ? undefined : startListeningForLeave) as UseTabReopenEffectReturn<AutoListen>;
}

export default useTabReopenEffect;