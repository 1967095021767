import React, {FunctionComponent, useState} from "react";
import {ApplicationPipelineModel} from "../../../model/ApplicationPipelineModel";
import ApplicantTooltip from "../../../../applicants/components/ApplicantTooltip";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBank,
    faBell,
    faBuilding,
    faCalendarCheck,
    faCalendarPlus,
    faCopy,
    faEllipsisVertical,
    faEnvelope, 
    faEye, 
    faEyeSlash,
    faMobileAlt,
    faPaperPlane,
    faPlusSquare,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Popover} from "react-bootstrap";
import ApplicantActionsMenu, {ApplicantActionsMenuProps} from "../../../../applicants/components/ApplicantActionsMenu";
import {useTranslate} from "../../../../../common/helpers/useTranslate";
import SubstatusesInputField from "../../../../../common/components/input-fields/SubstatusesInputField";
import NumberInputField, {NumberInputFieldKind} from "../../../../../common/components/input-fields/NumberInputField";
import DateTimeInputField from "../../../../../common/components/input-fields/DateTimeInputField";
import FollowUpInlineInputField from "../../../../../common/components/input-fields/FollowUpInlineInputField";
import moment from "moment";
import {PipelineCommunicationConfig} from "../../../model/ApplicationPipelineCommunicationConfig";
import {isValueSet} from "../../../../../common/helpers/isValueSet";
import Detail from "./ItemDetail";
import copy from "copy-to-clipboard";
import Tooltip from "../../../../../common/components/Tooltip";
import Translate_i18next from "../../../../../common/components/Translate_i18next";
import AddCommentAction from "../../../../../common/components/table-cells/actions/AddCommentAction";
import getProgressCompletionStyling from "../../../../applicant/helpers/getProgressCompletionStyling";
import {MessageType} from "../../../../../common/models/MessageType";

type ApplicationActions = Extract<
    keyof ApplicantActionsMenuProps,
    'onSubstatusesChange' | 
    'onCommentSave' | 
    'onDenyApplication' | 
    'onCreateNewApplication' | 
    'onUnhideApplication' | 
    'onHideApplication'
>;
interface ItemProps extends Pick<ApplicantActionsMenuProps, ApplicationActions> {
    application: ApplicationPipelineModel;
    compactView?: boolean;
    differentiateProducts?: boolean;
    onReminderChange?: (reminder: boolean) => void;
    onFollowUpChange?: (followUp?: string | null) => void;
    onSendSmsClick?: (config: PipelineCommunicationConfig) => void;
    onSendEmailClick?: (config: PipelineCommunicationConfig) => void;
    onSendSmsAndEmailClick?: (config: PipelineCommunicationConfig) => void;
}
const Item: FunctionComponent<ItemProps> = ({
    application,
    compactView,
    differentiateProducts,
    onSubstatusesChange,
    onCommentSave,
    onDenyApplication,
    onReminderChange,
    onFollowUpChange,
    onCreateNewApplication,
    onSendSmsClick,
    onSendEmailClick,
    onSendSmsAndEmailClick,
    onHideApplication,
    onUnhideApplication
}) => {
    const translate = useTranslate();
    const {
        firstName,
        lastName,
        primaryLanguage,
        email,
        mobileNumber,
        personId,
        applicationId,
        publicId,
        hidden,
        status,
        product,
        submittedDate,
        lastStatusChange,
        followUpDate,
        followUpReminder,
        appliedAmount,
        bankAmount,
        substatusIds,
        hasProperty,
        applicationFormProgress
    } = application;
    const [editing, setEditing] = useState<ApplicationPipelineModel>();
    const config: PipelineCommunicationConfig = {
        product,
        mobileNumber,
        email,
        applicationId,
        publicId,
        personId,
        firstName,
        lastName,
        primaryLanguage
    }

    const renderInlineApplicantActions = () => (
        <div className="inline-applicant-actions">
            {!compactView ?
                <Tooltip content={<Translate_i18next id="NEW_APPLICATION"/>}>
                    <FontAwesomeIcon icon={faPlusSquare} onClick={onCreateNewApplication}/>
                </Tooltip> : null
            }
            {!compactView ?
                <Tooltip content={<Translate_i18next id="ADD_COMMENT"/>}>
                    <AddCommentAction
                        substatusIds={substatusIds}
                        email={email}
                        mobileNumber={mobileNumber}
                        loanStatus={status}
                        onSave={onCommentSave}
                        onSubstatusesChange={onSubstatusesChange}
                        onDenyApplication={onDenyApplication}
                        onCommunicationTemplateChange={(template, type) => {
                            switch (type) {
                                case MessageType.Email:
                                    onSendEmailClick?.({...config, defaultTemplate: template})
                                    break;
                                case MessageType.SMS:
                                    onSendSmsClick?.({...config, defaultTemplate: template})
                                    break;
                                default:
                                    onSendSmsAndEmailClick?.({...config, defaultTemplate: template})
                            }
                        }}
                    />
                </Tooltip> : null
            }
            {(!compactView && onHideApplication && onUnhideApplication) ?
                <Tooltip content={<Translate_i18next id={hidden ? 'UNHIDE_APPLICATION' : 'HIDE_APPLICATION'}/>}>
                    <FontAwesomeIcon
                        icon={hidden ? faEye : faEyeSlash}
                        onClick={hidden ? onUnhideApplication : onHideApplication}
                    />
                </Tooltip> : null
            }
            {mobileNumber ? 
                <Tooltip content={<Translate_i18next id="COPY_MOBILE_NUMBER"/>}>
                    <FontAwesomeIcon icon={faCopy} onClick={() => copy(mobileNumber!)}/>
                </Tooltip>
                : null
            }
            <Tooltip content={<Translate_i18next id="SEND_SMS"/>}>
                <FontAwesomeIcon icon={faMobileAlt} onClick={() => onSendSmsClick?.(config)}/>
            </Tooltip>
            <Tooltip content={<Translate_i18next id="SEND_EMAIL"/>}>
                <FontAwesomeIcon icon={faEnvelope} onClick={() => onSendEmailClick?.(config)}/>
            </Tooltip>
            <Tooltip content={<Translate_i18next id="SEND_EMAIL_AND_SMS"/>}>
                <FontAwesomeIcon icon={faPaperPlane} onClick={() => onSendSmsAndEmailClick?.(config)}/>
            </Tooltip>
        </div>
    )

    return (
        <div
            className={`application-item product-${differentiateProducts ? product : 'does-not-matter'} ${hidden ? 'hidden' : ''}`}
            key={applicationId}
            style={{
                '--progress-width': `${applicationFormProgress || 0}%`,
                '--progress-color': getProgressCompletionStyling(applicationFormProgress).backgroundColor,
            } as React.CSSProperties & Record<string, string>}
        >
            <div className="header">
                <ApplicantTooltip
                    isApplicationHidden={hidden}
                    applicationId={applicationId}
                    personId={personId}
                    firstName={firstName}
                    lastName={lastName}
                    substatusIds={substatusIds}
                    email={email}
                    mobileNumber={mobileNumber}
                    loanStatus={status}
                    onCreateNewApplication={onCreateNewApplication}
                    onCommentSave={onCommentSave}
                    onSubstatusesChange={onSubstatusesChange}
                    onDenyApplication={onDenyApplication}
                    onSendSmsClick={template => onSendSmsClick?.({...config, defaultTemplate: template})}
                    onSendEmailClick={template => onSendEmailClick?.({...config, defaultTemplate: template})}
                    onSendSmsAndEmailClick={template => onSendSmsAndEmailClick?.({...config, defaultTemplate: template})}
                    onHideApplication={onHideApplication}
                    onUnhideApplication={onUnhideApplication}
                >
                    <NavLink to={`/applicant/${personId}/application/${applicationId}`}>
                        {(firstName || lastName) ?
                            `${firstName} ${lastName}` :
                            translate('NO_DATA')
                        }
                    </NavLink>
                </ApplicantTooltip>
                <div className="actions">
                    {hasProperty ?
                        <FontAwesomeIcon icon={faBuilding}/>
                        : null
                    }
                    {compactView ?
                        <OverlayTrigger
                            placement="top"
                            trigger="click"
                            rootClose={true}
                            overlay={
                                <Popover style={{border: 'none'}}>
                                    <ApplicantActionsMenu
                                        isApplicationHidden={hidden}
                                        substatusIds={substatusIds}
                                        email={email}
                                        mobileNumber={mobileNumber}
                                        loanStatus={status}
                                        onCreateNewApplication={onCreateNewApplication}
                                        onCommentSave={onCommentSave}
                                        onSubstatusesChange={onSubstatusesChange}
                                        onDenyApplication={onDenyApplication}
                                        onSendSmsClick={template => onSendSmsClick?.({...config, defaultTemplate: template})}
                                        onSendEmailClick={template => onSendEmailClick?.({...config, defaultTemplate: template})}
                                        onSendSmsAndEmailClick={template => onSendSmsAndEmailClick?.({...config, defaultTemplate: template})}
                                        onHideApplication={onHideApplication}
                                        onUnhideApplication={onUnhideApplication}
                                    />
                                </Popover>
                            }
                        >
                            <FontAwesomeIcon icon={faEllipsisVertical}/>
                        </OverlayTrigger> : null
                    }
                </div>
            </div>
            <div className="content">
                <div className="details">
                    <SubstatusesInputField
                        substatusIds={substatusIds || []}
                        editMode={!compactView}
                        inlineEditing={true}
                        onlyImportant={compactView ? status : undefined}
                        onSubstatusesChanged={onSubstatusesChange}
                    />
                    <Detail
                        icon={isValueSet(bankAmount) ? faBank : faUser}
                        descriptionKey={isValueSet(bankAmount) ? 'APPLICANTS_VIEW.TABLE_HEADERS.GRANTED_AMOUNT' : 'REQUESTED_LOAN_AMOUNT'}
                    >
                        <NumberInputField
                            name="loanAmount"
                            value={isValueSet(bankAmount) ? bankAmount : appliedAmount}
                            kind={NumberInputFieldKind.Money}
                        />
                    </Detail>
                    <Detail
                        icon={lastStatusChange ? faCalendarCheck : faCalendarPlus}
                        descriptionKey={lastStatusChange ? 'LAST_STATUS_CHANGE' : 'SUBMITTED_DATE'}
                    >
                        <DateTimeInputField
                            name="date"
                            value={lastStatusChange || submittedDate}
                        />
                    </Detail>
                    <Detail
                        descriptionKey="FOLLOW_UP_DATE"
                        icon={!followUpDate ? faBell : undefined}
                    >
                        <FollowUpInlineInputField
                            followUpDate={editing ? editing.followUpDate : followUpDate}
                            hasReminder={followUpReminder}
                            editMode={!!editing}
                            onFocus={() => setEditing(application)}
                            onBlur={() => {
                                if (editing?.followUpDate !== followUpDate) {
                                    onFollowUpChange?.(editing?.followUpDate);
                                }
                                setEditing(undefined);
                            }}
                            onChange={date => setEditing({...application, followUpDate: moment(date).toISOString()})}
                            onReminderClick={() => onReminderChange?.(!followUpReminder)}
                        />
                    </Detail>
                </div>
                {renderInlineApplicantActions()}
            </div>
        </div>
    )
}
export default Item;