import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer } from "react";
import './active-session-manager.css';
import { ActiveSessionManagerPopup } from "./ActiveSessionManagerPopup";
import { sessionReducer, initialState } from './sessionReducer';
import { calculateEndOfSession, calculateRemainingTime } from './dateLogic';
import { getActiveSession, startActiveSession, stopActiveSession } from "../../api/activeSession";
import { ActiveSession } from "../../models/ActiveSession";
import { useSignalR } from "../../helpers/SignalR";
import { useToastMessages } from "../../actions/ToastMessagesActionCreator";
import { useTranslate_i18next } from "../../useTranslate_i18next";
import { Spinner } from "react-bootstrap";
import Tooltip from "../Tooltip";

interface ActiveSessionManagerProps {
    defaultWorkingTime: number;
}

export const ActiveSessionManager = (props: ActiveSessionManagerProps) => {
    const [state, dispatch] = useReducer(sessionReducer, initialState);
    
    useEffect(() => {
        const loadActiveSession = async () => {
            const session = await getActiveSession();

            if(session) {
                dispatch({ type: 'START_SESSION', sessionEnd: session.end });
            } else {
                dispatch({ type: 'STOP_SESSION'});
            }
        };
        
        dispatch({ type: 'START_PROCESSING_REQUEST'});
        loadActiveSession();
    }, []);

    useSignalR<ActiveSession>("StartActiveSession", (session) => dispatch({ type: 'START_SESSION', sessionEnd: new Date(session.end) }));
    useSignalR("StopActiveSession", () => dispatch({ type: 'STOP_SESSION' }));
    
    const [ showToastMessage ] = useToastMessages();
    const translate = useTranslate_i18next();
    const showError = () => showToastMessage('error', translate('ACTION_FAILED'), translate('ERRORS.SOMETHING_WENT_WRONG'), 'SOMETHING_WENT_WRONG');

    useEffect(() => {
        if (state.sessionEnd === null) {
            return;
        }
        
        const interval = setInterval(() => {
            const remainingTime = calculateRemainingTime(state.sessionEnd!);
            dispatch({ type: 'UPDATE_REMAINING_TIME', remainingTime });
        }, 1000);

        return () => clearInterval(interval);
    }, [state.sessionEnd]);

    if (state.isProcessing) {
        return <div className="active-session-manager"><Spinner animation="border" variant="primary" size="sm" /></div>;
    }

    if (state.sessionEnd === null) {
        const play = () => dispatch({ type: 'SHOW_POPUP' });
        const cancel = () => dispatch({ type: 'HIDE_POPUP' });
        const startSession = async (sessionEnd: Date) => {
            dispatch({ type: 'START_PROCESSING_REQUEST'});
            const result = await startActiveSession(sessionEnd);

            if(!result.success) {
                dispatch({ type: 'STOP_PROCESSING_REQUEST'});
                showError();
            }
        }

        const renderPopup = () => {
            if (!state.showPopup) {
                return null;
            }

            return (
                <ActiveSessionManagerPopup
                    sessionEnd={calculateEndOfSession(props.defaultWorkingTime)} 
                    startSession={startSession} 
                    cancel={cancel} 
                />
            );
        }

        return (
            <div className="active-session-manager">
                <Tooltip content={translate('ACTIVE_SESSION.START_SESSION')} placement="bottom">
                    <div className="btn" onClick={play}>
                        <FontAwesomeIcon icon={faPlay}/>
                    </div>
                </Tooltip>
                {renderPopup()}
            </div>
        );
    }

    const stop = async () => {
        dispatch({ type: 'START_PROCESSING_REQUEST'});
        const result = await stopActiveSession();

        if(!result.success) {
            dispatch({ type: 'STOP_PROCESSING_REQUEST'});
            showError();
        }
    }

    return (
        <div className="active-session-manager">
            <div className="session-time">{state.remainingTime}</div>
            <Tooltip content={translate('ACTIVE_SESSION.STOP_SESSION')} placement="bottom">
                <div className="btn" onClick={stop}>
                    <FontAwesomeIcon icon={faStop}/>
                </div>
            </Tooltip>
        </div>
    );
}
